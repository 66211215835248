<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Framework Update Report: Archive Comparison - Table View</h3>

    <img srcset="/docimages/framework_report_table_view.png 1.1x" class="k-help-img float-right">
    <p class="k-no-clear"> The From the table view of the <span v-html="link('framework_update', 'Framework Update Report')"></span> archive comparison, a user can:
        
        <ol>
            <li>Search for a particular item, code or phrase from the framework</li>
            <li>Select any row to drill into the details of what changed</li>
            <li>Switch to the <strong>Track Changes - Tree View <nobr><v-icon small>fa-solid fa-tree</v-icon></nobr></strong> to have the option to navigate through parent and child items of the framework</li>
            <li>View <strong>item metadata</strong> <nobr><v-icon small>fa-solid fa-code</v-icon></nobr> (including the <strong>JSON format</strong> and item <strong>change history</strong>)</li>
        </ol>
    </p>
       
    <p>A few tips for navigating this Table view:
        <ul>
            <li>Begin by verifying the filters applied for UPDATE TYPES TO SHOW.</li>
            <li>Scan the updates column for the types of updates that matter most, and adjust the filters if you only want to see a particular type of update (i.e. remove all updates except NEW, to view CREATED items).</li>
            <li>Use the arrow <nobr><v-icon small>fa-solid fa-circle-arrow-left</v-icon></nobr> button to return to the SHOW ALL ARCHIVES view of the framework to change any of your original selections for comparison.</li>
        </ul>

    </p>
    
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>